import React from "react"
import * as headerStyles from "./footer.module.scss"
import logo from "../images/unicorn-logo.svg"
import { Link } from "gatsby"

export default function Footer () {
    return <div className={headerStyles.naviMainContainer}>
            <Link className="imageLink" to="/">
                <div className={headerStyles.logoArea}>
                    <img className={headerStyles.logo} src={logo} alt="Logo" />
                    <div className={headerStyles.logoFont}>
                        <h2>Black Unicorn Studio</h2>
                        <span>wedding photography</span>
                    </div>
                </div>
            </Link>
            <div style={{flex:"1"}}></div>
            <div className={headerStyles.links}>
                <Link className={headerStyles.naviLinks} to="/datenschutz/">datenschutz</Link>
                <Link className={headerStyles.naviLinks} to="/impressum/">impressum</Link>
            </div>
        </div>
    }