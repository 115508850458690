import React from "react"
import * as styles from "./navi.module.scss"
import logo from "../images/unicorn-logo.svg"
import { Link } from "gatsby"
import '../styles/index.scss';


export default function Navi () {
return <div className={styles.naviMainContainer}>
        <Link className="imageLink" to="/">
        <div className={styles.logoArea}>
            <img className={styles.logo} src={logo} alt="Logo" />
            <div className={styles.logoFont}>
                <h2>Black Unicorn Studio</h2>
                <span>wedding photography</span>
            </div>
        </div>
        </Link>
        <div style={{flex:"1"}}></div>
        <div className={styles.naviLinkGroup}>
            <div className={styles.naviLinks}>
                <Link className="link link--mneme" to="/hochzeiten/">hochzeiten</Link>
            </div>
            <div  className={styles.naviLinks}>
                <Link  className="link link--mneme" to="/lili/">lili</Link>
            </div>
            <div className={styles.naviLinks}>
                <Link className="link link--mneme" to="/#contact">contact</Link>
            </div>
        </div>
    </div>
}


